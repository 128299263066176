import React from "react"
import Button from "../components/common/Button"
import PageHeader from "../components/common/PageHeader"
import Layout from "../components/layout"

export default function CtrmSe() {
  return (
    <Layout lang="sv">
      <div className="space-y-8">
        <PageHeader text="Commodity trading &amp; risk management system" />

        <div
          id="wp-page"
          className="grid grid-cols-1 md:grid-cols-2 gap-8 space-y-4 lg:space-y-0"
        >
          <div>
            <div className="space-y-4 bg-sixth p-4">
              <h3>Logga in</h3>
              <form className="space-y-4">
                <formgroup className="flex flex-col ">
                  <label for="username" className="pb-2">
                    Användarnamn{" "}
                  </label>
                  <input type="text" name="username" className="p-4" />
                </formgroup>
                <formgroup className="flex flex-col">
                  <label for="password" className="pb-2">
                    Lösenord{" "}
                  </label>
                  <input type="password" name="password" className="p-4" />
                </formgroup>
                <Button link="" text="Skicka" />
              </form>
            </div>
          </div>
          <div>
            <div className="space-y-6 leading-relaxed">
              <div className="space-y-4 leading-relaxed">
                <h2 className="">
                  Risk- and Revenue Reporting in our custom CTRM-system
                </h2>
                <div>
                  <p>
                    Bodecker Partners har utvecklat ett skräddarsytt webbaserat
                    portföljhanteringssystem (”CTRM”). Vårt CTRM är skräddarsytt
                    för vind- och solkraftstillgångar och ger en tydlig
                    överblick och nära realtidsstatus för portföljpositioner &
                    värde, risker och kassaflödesprognoser. Vi erbjuder detta
                    system till våra portföljförvaltningskunder, men nu även som
                    fristående riskuppföljningssystem med månadsabonnemang.
                  </p>
                </div>
                <h4 className="font-bold">Vår CTRM innehåller: </h4>
                <div>
                  <ul>
                    <li>
                      Daglig positioner, portföljvärde och
                      pris/volym/profilrisker
                    </li>
                    <li>
                      Alla ingångna säkringar; PPA, optioner, terminskontrakt,
                      elcertifikat, ursprungsgarantier, utsläppsrätter
                    </li>
                    products and Electricity certificates
                    <li>Motpartslista samt kreditexponering</li>
                    Counterparty container and credit risk exposure
                    <li>Pris- och ”capture rate” kurvor per elprisområde</li>
                  </ul>
                </div>
              </div>
              <div className="space-y-4 bg-sixth p-4">
                <div>
                  <h4 className="font-bold">
                    Intresserad av en demo eller mer information?
                  </h4>
                  <p>
                    Kontakta{" "}
                    <a href="mailto:sevdie@bodeckerpartners.com">
                      sevdie@bodeckerpartners.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
